import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Navbar } from "../../public/Navbar";
import "../../../styles/company-page.scss";
import { Collapse, Table, Input, Button, Form, Popconfirm } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SendOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MailOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import axios from "axios";
import { Notification } from "../../hooks/Notification";

export const CompanyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, name } = location.state;
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [newMember, setNewMember] = useState("");
  const [displayForm, setDisplayForm] = useState(false);
  const [company, setCompany] = useState({ id: id, name: name });
  const [newCompany, setNewCompany] = useState();
  const [open, setOpen] = useState(false);
  const [notif, setNotif] = useState({ type: "warning" });
  const token = Cookies.get("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/usr-mgt/get-organization-users?organizationId=${id}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        setMembers(
          response.data.joinedUsers.map((member) => ({
            id: member?.userId?._id,
            email: member.email,
            inviteStatus: member.inviteStatus,
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    if (loading && token) {
      fetchData();
    }
  }, [loading, token]);

  const addMember = async (existingEmail = "") => {
    try {
      if ((existingEmail || newMember) && id) {
        await axios.post(
          `${process.env.REACT_APP_API}/org-mgt/send-invite`,
          {
            email: existingEmail.length ? existingEmail : newMember,
            orgId: id,
          },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        setOpen(true);
        setNotif({
          type: "confirm",
          text: "Invite sent",
        });
        setLoading(true);
        setNewMember("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditCompany = async () => {
    try {
      if (newCompany?.id && newCompany?.name) {
        await axios.post(
          `${process.env.REACT_APP_API}/org-mgt/edit-org?id=${id}`,
          { name: newCompany.name },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        setCompany({ ...company, name: newCompany.name });
        setDisplayForm(false);
      } else {
        setDisplayForm(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const memberStatusDisplay = (memberStatus) => {
    if (memberStatus === "pending") {
      return (
        <>
          <MailOutlined style={{ color: "blue" }} />
        </>
      );
    } else if (memberStatus === "accepted") {
      return <CheckCircleOutlined style={{ color: "green" }} />;
    } else if (memberStatus === "rejected") {
      return <CloseCircleOutlined style={{ color: "red" }} />;
    }
  };

  const handleDeleteMember = async (memberId, memberEmail) => {
    try {
      if (memberId.length) {
        const resDelete = await axios.post(
          `${process.env.REACT_APP_API}/usr-mgt/remove-organization-user`,
          { userId: memberId, orgId: id },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        if (resDelete?.status === 200) {
          setLoading(true);
          setNotif({
            type: "delete",
            text: "User: " + memberEmail + " has been deleted.",
          });
          setOpen(true);
        }
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const columns = [
    {
      title: "Member",
      key: "member",
      render: (text, record, index) =>
        index === members.length ? (
          <Input
            value={newMember}
            placeholder="Enter email"
            onChange={(e) => setNewMember(e.target.value)}
          />
        ) : (
          <p className="member-row">
            {record.email}
            {memberStatusDisplay(record?.inviteStatus)}
          </p>
        ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record, index) =>
        index < members.length ? (
          <>
            <Popconfirm
              title="Delete member"
              description=""
              okText="Delete"
              onConfirm={() => handleDeleteMember(record.id, record.email)}
            >
              <Button
                type="primary"
                danger
                style={{ margin: "8px" }}
                disabled={record?.id ? false : true}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            {record?.inviteStatus !== "accepted" && (
              <Popconfirm
                title="Resend invite"
                description=""
                okText="Send invite"
                onConfirm={() => {
                  addMember(record.email);
                }}
              >
                <Button type="primary" style={{ margin: "8px" }}>
                  <SendOutlined />
                </Button>
              </Popconfirm>
            )}
          </>
        ) : (
          <Button type="primary" onClick={addMember}>
            Send invite
          </Button>
        ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Company infos",
      children: displayForm ? (
        <Form>
          <Form.Item label="Name">
            <Input
              defaultValue={company?.name}
              placeholder="Enter company name"
              onChange={(e) =>
                setNewCompany({ id: company?.id, name: e.target.value })
              }
            />
          </Form.Item>
          <Button type="primary" onClick={() => handleEditCompany()}>
            Save
          </Button>
        </Form>
      ) : (
        <>
          <p>Name: {company?.name}</p>
          <Button type="primary" onClick={() => setDisplayForm(true)}>
            Edit Infos <EditOutlined />
          </Button>
        </>
      ),
    },
    {
      key: "2",
      label: "Company members",
      children: (
        <Table
          dataSource={[...members, newMember]}
          columns={columns}
          rowKey={(record, index) => index}
          pagination={false}
        />
      ),
    },
  ];

  return (
    <>
      <Navbar displayLinks={false} privateNav={true} />
      <div className="company-page">
        <div className="top-btn">
          <Button onClick={() => navigate("/dashboard")}>
          <LeftOutlined />
        </Button>
        </div>
        <Collapse items={items} defaultActiveKey={["1", "2"]} />
        <Notification
          open={open}
          setOpen={setOpen}
          type={notif.type}
          text={notif?.text}
        />
      </div>
    </>
  );
};
