import React from "react";
import logo from "../../assets/img/mimqchat-logo-rectangle-min.png";
import "../../styles/navbar.scss";
import Cookies from "js-cookie";

export const Navbar = ({ displayLinks, privateNav = false }) => {
  return (
    <div className="navbar">
      <a href="/">
        <img src={logo} alt="MimQ Chat" id="logo" />
      </a>
      {displayLinks && !privateNav && (
        <ul className="content">
          <li>
            <a href="#download-section">Download</a>
          </li>
          <li>
            <a href="/dashboard">Login</a>
          </li>
        </ul>
      )}
      {!displayLinks && !privateNav && (
        <ul className="content">
          <li>
            <a href="/">Back to website</a>
          </li>
        </ul>
      )}
      {!displayLinks && privateNav && (
        <ul className="content">
          <li>
            <a href="/dashboard">Dashboard</a>
          </li>
          <li onClick={() => Cookies.remove("token")}>
            <a href="/">Logout</a>
          </li>
        </ul>
      )}
      <span className="blur"></span>
    </div>
  );
};
