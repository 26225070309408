import React from "react";
import image from "../../../assets/img/bubble-min.png";
import RandomToken from "../../hooks/RandomToken";

export const AboutUs = () => {
  return (
    <section className="about-us">
      <img src={image} alt="" />
      <h1>Welcome to MimQChat</h1>
      <RandomToken />
      <div className="text-box">
        <span className="blur"></span>
        <p>
          At MimQChat, a collective of software engineers, marketing
          specialists, and visionary partners have come together with one goal:
          to bring you the highest standard of secure communication. We
          specialize in encryption technology to ensure that your conversations
          remain private and protected.
        </p>
      </div>
    </section>
  );
};
