import React from "react";
import logo from "../assets/img/mimqchat-logo-rectangle-min.png";
import { Link } from "react-router-dom";
import "../styles/footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <img src={logo} alt="" className="logo" />
        <div className="content">
          <p>Contact</p>
          <p id="contact">
            <b>admin@mimqtechnologies.com</b>
          </p>
        </div>
        <div className="content">
          <Link to="terms-and-conditions">Terms & Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
      <p>
        Made with ❤️ by{" "}
        <a href="https://boring.tech/" target="_blank">
          BoringTech
        </a>
      </p>
      <p id="credits">
        Credits: Download icons created by
        <a href="https://www.flaticon.com/free-icons/logos" title="logos icons">
          Flaticon
        </a>
      </p>
    </div>
  );
};
