import React from "react";
import "../../../styles/home.scss";
import { AboutUs } from "./AboutUs";
import { AboutApp } from "./AboutApp";
import { Download } from "./Download";
import { ContactUs } from "./ContactUs";
import { Navbar } from "../Navbar";

export const Home = () => {
  return (
    <>
      <Navbar displayLinks={true} />
      <div className="home-page">
        <AboutUs />
        <AboutApp />
        <Download />
        <ContactUs />
      </div>
    </>
  );
};
