import React, { useState } from "react";
import "../../../styles/login.scss";
import { Navbar } from "../Navbar";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [infos, setInfos] = useState({ email: "", confirmEmail: "" });
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      infos &&
      infos.email.length &&
      infos.confirmEmail.length &&
      infos.email === infos.confirmEmail
    ) {
      navigate("/login");
    } else {
      setErrorMsg("Error, try again");
    }
  };

  return (
    <>
      <Navbar displayLinks={false} />
      <div className="login">
        <form>
          <h3>Forgot Password</h3>
          <label htmlFor="password">Email</label>
          <input
            type="text"
            onChange={(e) => {
              setInfos({ ...infos, email: e.target.value });
              setErrorMsg("");
            }}
          />
          <label htmlFor="confirm-email">Confirm Email</label>
          <input
            type="text"
            onChange={(e) => {
              setInfos({ ...infos, confirmEmail: e.target.value });
              setErrorMsg("");
            }}
          />
          <button className="button" onClick={(e) => onSubmit(e)}>
            Send Email
          </button>
          {errorMsg.length ? <p className="error">{errorMsg}</p> : ""}
          <span className="blur"></span>
        </form>
      </div>
    </>
  );
};
