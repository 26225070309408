// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/Montserrat/static/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/Montserrat/static/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "title";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: "regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
*,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

h1 {
  font-size: 40px;
  margin: 24px 0px;
  font-family: "title";
}

h2,
h3 {
  font-size: 32px;
  margin: 24px 0px;
  font-family: "title";
}

h4,
p,
a,
.button,
label,
input {
  font-size: 22px;
  font-family: "regular";
  line-height: 1.6em;
}

.button {
  text-decoration: none;
  background-color: white;
  border: 4px solid #6c95ff;
  transition: background-color 0.3s ease-in-out;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 24px;
  margin: 8px;
  min-width: 240px;
}
.button .icon {
  height: 48px;
  margin-left: 8px;
  object-fit: contain;
}
.button:hover {
  cursor: pointer;
  background-color: #6c95ff;
  transition: background-color 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/styles/style.scss"],"names":[],"mappings":"AAQA;EACE,oBAAA;EACA,4CAAA;AAPF;AAUA;EACE,sBAAA;EACA,4CAAA;AARF;AAWA;;EAEE,UAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,uBAAA;AATF;;AAYA;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;AATF;;AAYA;;EAEE,eAAA;EACA,gBAAA;EACA,oBAAA;AATF;;AAYA;;;;;;EAME,eAAA;EACA,sBAAA;EACA,kBAAA;AATF;;AAYA;EACE,qBAAA;EACA,uBArDY;EAsDZ,yBAAA;EACA,6CAAA;EACA,mBAlDO;EAmDP,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;AATF;AAWE;EACE,YAAA;EACA,gBAAA;EACA,mBAAA;AATJ;AAYE;EACE,eAAA;EACA,yBAvEY;EAwEZ,6CAAA;AAVJ","sourcesContent":["$light-color: white;\n$dark-color: #1f2b4a;\n$primary-color: #6c95ff;\n\n$responsive: 980px;\n\n$radius: 40px;\n\n@font-face {\n  font-family: \"title\";\n  src: url(\"../assets/fonts/Montserrat/static/Montserrat-Bold.ttf\");\n}\n\n@font-face {\n  font-family: \"regular\";\n  src: url(\"../assets/fonts/Montserrat/static/Montserrat-Regular.ttf\");\n}\n\n*,\nhtml {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  overflow-x: hidden;\n  scroll-behavior: smooth;\n}\n\nh1 {\n  font-size: 40px;\n  margin: 24px 0px;\n  font-family: \"title\";\n}\n\nh2,\nh3 {\n  font-size: 32px;\n  margin: 24px 0px;\n  font-family: \"title\";\n}\n\nh4,\np,\na,\n.button,\nlabel,\ninput {\n  font-size: 22px;\n  font-family: \"regular\";\n  line-height: 1.6em;\n}\n\n.button {\n  text-decoration: none;\n  background-color: $light-color;\n  border: 4px solid $primary-color;\n  transition: background-color 0.3s ease-in-out;\n  border-radius: $radius;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  padding: 4px 24px;\n  margin: 8px;\n  min-width: 240px;\n\n  .icon {\n    height: 48px;\n    margin-left: 8px;\n    object-fit: contain;\n  }\n\n  &:hover {\n    cursor: pointer;\n    background-color: $primary-color;\n    transition: background-color 0.3s ease-in-out;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
