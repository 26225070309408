import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./styles/style.scss";
import { Home } from "./components/public/home/Home";
import { PrivacyPolicy } from "./components/public/policies/PrivacyPolicy";
import { TermsConditions } from "./components/public/policies/TermsConditions";
import { Login } from "./components/public/login/Login";
import { Verified } from "./components/public/login/Verified";
import { CompanyPage } from "./components/private/company/CompanyPage";
import { Dashboard } from "./components/private/dashboard/Dashboard";
import PrivateRoutes from "./PrivateRoutes";
import { Footer } from "./components/Footer";
import { ResetPassword } from "./components/public/login/ResetPassword";
import { ForgotPassword } from "./components/public/login/ForgotPassword";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsConditions />}
        />
        {["/privacy-policies", "/privacy-policy"].map((path) => (
          <Route exact path={path} element={<PrivacyPolicy />} />
        ))}
        <Route exact path="/login" element={<Login />} />
        <Route path="/verified" element={<Verified />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route element={<PrivateRoutes />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route path="/company/:id" element={<CompanyPage />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
