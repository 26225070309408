import React, { useState } from "react";
import "../../../styles/login.scss";
import { Navbar } from "../Navbar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

export const Login = () => {
  const navigate = useNavigate();
  const [infos, setInfos] = useState({ email: "", password: "" });
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (infos && infos.email && infos.password) {
      axios
        .post(`${process.env.REACT_APP_API}/admin-mgt/login`, infos)
        .then((response) => {
          Cookies.set("token", response.data.admin.accessToken, { expires: 7 });
          navigate("/dashboard");
        })
        .catch((error) => {
          setErrorMsg("Invalid Email or Password");
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Navbar displayLinks={false} />
      <div className="login">
        <form>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            onChange={(e) => {
              setInfos({ ...infos, email: e.target.value });
              setErrorMsg("");
            }}
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            onChange={(e) => {
              setInfos({ ...infos, password: e.target.value });
              setErrorMsg("");
            }}
          />
          <button className="button" onClick={(e) => onSubmit(e)}>
            Login
          </button>
          {errorMsg.length ? <p className="error">{errorMsg}</p> : ""}
          <span className="blur"></span>
        </form>
      </div>
    </>
  );
};
