import React from "react";
import "../../../styles/verified.scss";
import { Navbar } from "../Navbar";
import { CheckCircleFilled } from "@ant-design/icons";

export const Verified = () => {
  return (
    <>
      <Navbar displayLinks={false} />
      <div className="verified">
        <h1>
          Email Verified <CheckCircleFilled />
        </h1>
      </div>
    </>
  );
};
