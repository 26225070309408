import React from "react";
import "../../../styles/policies.scss";
import { Navbar } from "../Navbar";

export const PrivacyPolicy = () => {
  return (
    <>
      <Navbar displayLinks={true} />
      <section className="policies">
        <h1 className="text-[40px] font-semibold py-6">PRIVACY NOTICE</h1>
        <p>Last updated August 9th, 2024</p>
        <p className="py-2 leading-[1.6em] text-lg">
          This privacy notice for MIMQ TECHNOLOGIES, LLC ("we," "us," or "our"),
          describes how and why we might collect, store, use, and/or share
          ("process") your information when you use our services ("Services"),
          such as when you:
        </p>
        <p className="py-2 leading-[1.6em] text-lg">
          <b>Questions or concerns?</b> Reading this privacy notice will help
          you understand your privacy rights and choices. If you do not agree
          with our policies and practices, please do not use our Services. If
          you still have any questions or concerns, please contact us at
          https://mimqtechnologies.com/contact.
        </p>
        <div>
          <h2 className="text-[40px] font-semibold py-6">
            SUMMARY OF KEY POINTS
          </h2>
          <p className="py-2 leading-[1.6em] text-lg">
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </p>
          <ul>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                When you visit, use, or navigate our Services, we may process
                personal information depending on how you interact with us and
                the Services, the choices you make, and the products and
                features you use.
              </p>
            </li>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                We do not process sensitive personal information.
              </p>
            </li>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                We may receive information from public databases, marketing
                partners, social media platforms, and other outside sources.
              </p>
            </li>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent. We process
                your information only when we have a valid legal reason to do
                so.
              </p>
            </li>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                <b>What are your rights?</b> Depending on where you are located
                geographically, the applicable privacy law may mean you have
                certain rights regarding your personal information.
              </p>
            </li>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                <b>How do you exercise your rights?</b> The easiest way to
                exercise your rights is by contacting us. We will consider and
                act upon any request in accordance with applicable data
                protection laws.
              </p>
            </li>
          </ul>
        </div>
        <div class="table-of-content">
          <h2 className="text-[40px] font-semibold py-6">TABLE OF CONTENTS</h2>
          <ul className="text-[#6C95FF]  leading-loose">
            <li className="hover:font-bold">
              <a href="#whatInfo">1. WHAT INFORMATION DO WE COLLECT?</a>
            </li>
            <li className="hover:font-bold">
              <a href="#howWeProcess">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
            </li>
            <li className="hover:font-bold">
              <a href="#shareInfo">
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </a>
            </li>
            <li className="hover:font-bold">
              <a href="#useCookies">
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </li>
            <li className="hover:font-bold">
              <a href="#handleLogins">
                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </a>
            </li>
            <li className="hover:font-bold">
              <a href="#infoTransferred">
                6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
              </a>
            </li>
            <li className="hover:font-bold">
              <a href="#keepInfo">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </li>
            <li className="hover:font-bold">
              <a href="#infoFromMinors">
                8. DO WE COLLECT INFORMATION FROM MINORS?
              </a>
            </li>
            <li className="hover:font-bold">
              <a href="#privacyRights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>
            <li className="hover:font-bold">
              <a href="#doNotTrackFeatures">
                10. CONTROLS FOR DO-NOT-TRACK FEATURES
              </a>
            </li>
            <li className="hover:font-bold">
              <a href="#makeUpdates">11. DO WE MAKE UPDATES TO THIS NOTICE?</a>
            </li>
            <li className="hover:font-bold">
              <a href="#contactUs">
                12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </a>
            </li>
            <li className="hover:font-bold">
              <a href="#reviewCollectData">
                13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </a>
            </li>
          </ul>
        </div>
        <div id="whatInfo">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            1. WHAT INFORMATION DO WE COLLECT?
          </h3>
          <h4 className="leading-[1.6em] font-bold text-lg">
            Personal information you disclose to us
          </h4>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>We collect personal information that you provide
              to us.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We collect personal information that you voluntarily provide to us
            when you register on the Services, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Services, or otherwise when you
            contact us.
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            <b>Sensitive Information.</b>We do not process sensitive
            information.
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
          <h4 className="leading-[1.6em] font-bold text-lg">
            Information automatically collected
          </h4>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>Some information — such as your Internet Protocol
              (IP) address and/or browser and device characteristics — is
              collected automatically when you visit our Services.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
        </div>
        <div id="howWeProcess">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>We process your information to provide, improve,
              and administer our Services, communicate with you, for security
              and fraud prevention, and to comply with law. We may also process
              your information for other purposes with your consent.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            <b>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </b>
          </p>
        </div>
        <div id="shareInfo">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>
            </i>
            We may share information in specific situations described in this
            section and/or with the following third parties.
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                - <b>Business Transfers.</b> We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </p>
            </li>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                - <b>Affiliates.</b> We may share your information with our
                affiliates, in which case we will require those affiliates to
                honor this privacy notice. Affiliates include our parent company
                and any subsidiaries, joint venture partners, or other companies
                that we control or that are under common control with us.
              </p>
            </li>
            <li>
              <p className="py-2 leading-[1.6em] text-lg">
                - <b>Business Partners.</b> We may share your information with
                our business partners to offer you certain products, services,
                or promotions.
              </p>
            </li>
          </ul>
        </div>
        <div id="useCookies">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>We may use cookies and other tracking technologies
              to collect and store your information.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
        </div>
        <div id="handleLogins">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>If you choose to register or log in to our
              Services using a social media account, we may have access to
              certain information about you.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            Our Services offer you the ability to register and log in using your
            third-party social media account details (like your Facebook or
            Twitter logins). Where you choose to do this, we will receive
            certain profile information about you from your social media
            provider. The profile information we receive may vary depending on
            the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as
            other information you choose to make public on such a social media
            platform.
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you on the relevant Services. Please note that we do not
            control, and are not responsible for, other uses of your personal
            information by your third-party social media provider. We recommend
            that you review their privacy notice to understand how they collect,
            use, and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </p>
        </div>
        <div id="infoTransferred">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>We may transfer, store, and process your
              information in countries other than your own.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            Our servers are located in. If you are accessing our Services from
            outside, please be aware that your information may be transferred
            to, stored, and processed by us in our facilities and by those third
            parties with whom we may share your personal information (see "WHEN
            AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and
            other countries.
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            If you are a resident in the European Economic Area (EEA) or United
            Kingdom (UK), then these countries may not necessarily have data
            protection laws or other similar laws as comprehensive as those in
            your country. However, we will take all necessary measures to
            protect your personal information in accordance with this privacy
            notice and applicable law.
          </p>
        </div>
        <div id="keepInfo">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>We keep your information for as long as necessary
              to fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </div>
        <div id="infoFromMinors">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            8. DO WE COLLECT INFORMATION FROM MINORS?
          </h3>
          <p>
            <i>
              <b>In Short:</b>We do not knowingly collect data from or market to
              children under 18 years of age.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Services, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent`s use of the Services. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at https://mimqtechnologies.com/contact.
          </p>
        </div>
        <div id="privacyRights">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            9. WHAT ARE YOUR PRIVACY RIGHTS?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>You may review, change, or terminate your account
              at any time.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            <b>
              <u>Withdrawing your consent:</u>
            </b>
            If we are relying on your consent to process your personal
            information, which may be express and/or implied consent depending
            on the applicable law, you have the right to withdraw your consent
            at any time. You can withdraw your consent at any time by contacting
            us by using the contact details provided in the section "HOW CAN YOU
            CONTACT US ABOUT THIS NOTICE?" below.
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            However, please note that this will not affect the lawfulness of the
            processing before its withdrawal nor, when applicable law allows,
            will it affect the processing of your personal information conducted
            in reliance on lawful processing grounds other than consent.
          </p>
          <h4 className="leading-[1.6em] font-bold text-lg">
            Account Information
          </h4>
          <p className="py-2 leading-[1.6em] text-lg">
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </p>
        </div>
        <div id="doNotTrackFeatures">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </div>
        <div id="makeUpdates">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            11. DO WE MAKE UPDATES TO THIS NOTICE?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            <i>
              <b>In Short:</b>Yes, we will update this notice as necessary to
              stay compliant with relevant laws.
            </i>
          </p>
          <p className="py-2 leading-[1.6em] text-lg">
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>
        <div id="contactUs" style={{ width: "100%" }}>
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            If you have questions or comments about this notice, you may contact
            us at admin@mimqtechnologies.com
          </p>
        </div>
        <div id="reviewCollectData">
          <h3 className="py-6 font-semibold text-[32px] text-[#6C95FF]">
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h3>
          <p className="py-2 leading-[1.6em] text-lg">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please contact us at
            admin@mimqtechnologies.com.
          </p>
        </div>
      </section>
    </>
  );
};
