import React, { useState, useEffect } from "react";
import "../../styles/random-token.scss";

const RandomToken = () => {
  const [randomString, setRandomString] = useState("");

  // Function to generate a random string of 50 characters
  const generateRandomString = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 128; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  useEffect(() => {
    // Update the random string every 100 milliseconds
    const interval = setInterval(() => {
      setRandomString(generateRandomString());
    }, 100);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="random-token">
      <span className="spin"></span>
      <p className="token">{randomString}</p>
    </div>
  );
};

export default RandomToken;
