import React from "react";
import image2 from "../../../assets/img/phone-bubbles-min.png";
import image1 from "../../../assets/img/bubbles01-min.png";

export const AboutApp = () => {
  return (
    <section className="about-app">
      <img src={image1} alt="" className="image one" />
      <img src={image2} alt="" className="image two" />
      <div className="text-box">
        <span className="blur"></span>
        <h2>MimQChat</h2>
        <p>
          MimQChat is our flagship mobile application, designed for those who
          value security and simplicity. Whether you're sending messages, making
          audio or video calls, every interaction is safeguarded by our advanced
          encryption algorithms.
        </p>
      </div>
    </section>
  );
};
