import React, { useState } from "react";
import "../../../styles/login.scss";
import { Navbar } from "../Navbar";
import { useNavigate } from "react-router-dom";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [infos, setInfos] = useState({ password: "", confirmPassword: "" });
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      infos &&
      infos.password.length &&
      infos.confirmPassword.length &&
      infos.password === infos.confirmPassword
    ) {
      navigate("/login");
    } else {
      setErrorMsg("Error, try again");
    }
  };

  return (
    <>
      <Navbar displayLinks={false} />
      <div className="login">
        <form>
          <h3>Reset Password</h3>
          <label htmlFor="password">New Password</label>
          <input
            type="password"
            onChange={(e) => {
              setInfos({ ...infos, password: e.target.value });
              setErrorMsg("");
            }}
          />
          <label htmlFor="confirm-password">Confirm Password</label>
          <input
            type="password"
            onChange={(e) => {
              setInfos({ ...infos, confirmPassword: e.target.value });
              setErrorMsg("");
            }}
          />
          <button className="button" onClick={(e) => onSubmit(e)}>
            Save New Password
          </button>
          {errorMsg.length ? <p className="error">{errorMsg}</p> : ""}
          <span className="blur"></span>
        </form>
      </div>
    </>
  );
};
