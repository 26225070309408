import React from "react";
import image1 from "../../../assets/img/bubble-min.png";
import image2 from "../../../assets/img/bubbles02-min.png";

export const ContactUs = () => {
  return (
    <section className="contact-us">
      <span className="img one">
        <img src={image1} alt="" />
      </span>
      <img src={image2} alt="" className="img two" />
      <div className="text-box">
        <span className="blur"></span>
        <h3>Contact Us</h3>
        <p>
          Have questions? We're here to help. Reach out to us for more
          information about MimQChat or to learn more about our services.
        </p>
        <p>
          <b>Businesses:</b> If you're interested in integrating MimQChat into
          your operations, contact us to create an organizational account. Our
          team is ready to assist you in securing your communication.
        </p>
        <h3 id="email">admin@mimqtechnologies.com</h3>
      </div>
    </section>
  );
};
