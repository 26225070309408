import React, { useState, useEffect } from "react";
import { Table, Button, Input, Popconfirm } from "antd";
import "../../../styles/dashboard.scss";
import { Navbar } from "../../public/Navbar";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Notification } from "../../hooks/Notification";
import Cookies from "js-cookie";
import axios from "axios";

export const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [newCompany, setNewCompany] = useState({ name: "" });
  const navigate = useNavigate();
  const token = Cookies.get("token");
  const [open, setOpen] = useState(false);
  const [notif, setNotif] = useState({ type: "warning" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/org-mgt/view-org`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        setCompanies(response.data.organizations);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    if (loading && token) {
      fetchData();
    }
  }, [loading, token]);

  const addCompany = async () => {
    try {
      if (newCompany.name) {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/org-mgt/create-org`,
          newCompany,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        if (response?.data?.organization) {
          setCompanies([...companies, response.data.organization]);
          setNewCompany({ name: "" });
          setOpen(true);
          setNotif({
            type: "confirm",
            text: "Organization created",
          });
          setLoading(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCompany = async (id, orgName) => {
    try {
      await axios
        .delete(`${process.env.REACT_APP_API}/org-mgt/delete-org?id=${id}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(true);
          setNotif({
            type: "delete",
            text: "Organization : " + orgName + " has been deleted.",
          });
          setOpen(true);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const viewCompany = (name, id) => {
    navigate(`/company/${name.replace(/\s+/g, "-")}`, {
      state: { id: id, name: name },
    });
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      render: (text, record, index) =>
        index === companies.length ? (
          <Input
            value={newCompany.name}
            placeholder="Enter company name"
            onChange={(e) =>
              setNewCompany({ ...newCompany, name: e.target.value })
            }
          />
        ) : (
          text
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) =>
        index < companies.length ? (
          <>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => viewCompany(record.name, record._id)}
            >
              <EyeOutlined />
            </Button>
            <Popconfirm
              title="Delete organization"
              description="Are you sure to delete this organization?"
              onConfirm={() => deleteCompany(record._id, record.name)}
              okText="Delete"
            >
              <Button type="primary" danger style={{ margin: "8px" }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        ) : (
          <Button type="primary" onClick={addCompany}>
            Save
          </Button>
        ),
    },
  ];

  return (
    <>
      <Navbar displayLinks={false} privateNav={true} />
      <div className="dashboard">
        <h2>DASHBOARD</h2>
        <Table
          dataSource={[...companies, { name: newCompany.name }]}
          columns={columns}
          rowKey={(record, index) => index}
          pagination={false}
        />
        <Notification
          open={open}
          setOpen={setOpen}
          type={notif.type}
          text={notif?.text}
        />
      </div>
    </>
  );
};
