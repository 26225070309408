import React from "react";
import image from "../../../assets/img/bubble-min.png";
import ios from "../../../assets/img/apple.png";
import android from "../../../assets/img/android.png";
import logo from "../../../assets/img/mimqchat-logo-square-min.png";

export const Download = () => {
  return (
    <section className="download" id="download-section">
      <img src={image} alt="" className="bg-img" />
      <div className="text-box">
        <span className="blur"></span>
        <div className="content">
          <h3>Available on IOS and Android</h3>
          <p>
            MimQChat offers an intuitive interface that makes secure
            communication effortless.
          </p>
          <h4>DOWNLOAD</h4>
          <div className="btn-box">
            <a
              className="button"
              href="https://apps.apple.com/us/app/mimqchat/id6504970960"
              target="_blank"
            >
              <p>IOS</p>
              <img src={ios} alt="" className="icon" />
            </a>
            <a
              className="button"
              href="https://play.google.com/store/apps/details?id=com.qchat.app"
              target="_blank"
            >
              <p>ANDROID</p>
              <img src={android} alt="" className="icon" />
            </a>
          </div>
        </div>
        <img src={logo} alt="" className="logo" />
      </div>
    </section>
  );
};
