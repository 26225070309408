import React, { useEffect } from "react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "../../styles/notification.scss";
import { Button } from "antd";

/**
 * @param type => "confirm", "warning", "delete"
 * @param text optional
 * @returns
 */

export const Notification = ({ open, setOpen, type, text }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setOpen(false);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [open]);

  const handleTitleIcon = () => {
    if (type === "confirm") {
      return (
        <CheckCircleOutlined style={{ color: "green" }} className="icon" />
      );
    } else if (type === "warning") {
      return (
        <ExclamationCircleOutlined
          style={{ color: "orange" }}
          className="icon"
        />
      );
    } else if (type === "delete") {
      return <CloseCircleOutlined style={{ color: "red" }} className="icon" />;
    }
  };

  return (
    <div className={`notification ${open ? "open" : "close"}`}>
      <div className="close-btn">
        <Button onClick={() => setOpen(false)}>
          <CloseOutlined />
        </Button>
      </div>
      <h3 className="title">
        {handleTitleIcon()}
        {type.toUpperCase()}
      </h3>
      {text && <p>{text}</p>}
    </div>
  );
};
